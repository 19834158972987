import React from 'react';
import { useTranslation } from '../i18n';
import { usePinnedArticleIds } from '../utils/articleUtils';
import Tooltip from './tooltip';

function PinToFav({ articleId }) {
  const { t } = useTranslation(['common']);
  const [pinnedArticleIds, setPinnedArticleIds] = usePinnedArticleIds();

  const isPinned = pinnedArticleIds.includes(articleId);

  const toggleIsPinned = e => {
    e.preventDefault();
    e.stopPropagation();
    if (isPinned) {
      setPinnedArticleIds(pinnedArticleIds.filter(id => id !== articleId));
    } else {
      setPinnedArticleIds([...pinnedArticleIds, articleId]);
    }
  };

  return (
    <Tooltip message={!isPinned ? t('addToFavorites') : t('removeFromFavorites')}>
      <span
        onClick={toggleIsPinned}
        onKeyPress={toggleIsPinned}
        role="button"
        tabIndex={0}
        style={{ cursor: 'pointer' }}
        className="pinIcon"
      >
        {isPinned ? (
          <svg viewBox="64 64 896 896" fill="currentColor">
            <path d="M878.3 392.1L631.9 145.7c-6.5-6.5-15-9.7-23.5-9.7s-17 3.2-23.5 9.7L423.8 306.9c-12.2-1.4-24.5-2-36.8-2-73.2 0-146.4 24.1-206.5 72.3-15.4 12.3-16.6 35.4-2.7 49.4l181.7 181.7-215.4 215.2a15.8 15.8 0 00-4.6 9.8l-3.4 37.2c-.9 9.4 6.6 17.4 15.9 17.4.5 0 1 0 1.5-.1l37.2-3.4c3.7-.3 7.2-2 9.8-4.6l215.4-215.4 181.7 181.7c6.5 6.5 15 9.7 23.5 9.7 9.7 0 19.3-4.2 25.9-12.4 56.3-70.3 79.7-158.3 70.2-243.4l161.1-161.1c12.9-12.8 12.9-33.8 0-46.8z" />
          </svg>
        ) : (
          <svg viewBox="64 64 896 896" fill="currentColor">
            <path d="M878.3 392.1L631.9 145.7c-6.5-6.5-15-9.7-23.5-9.7s-17 3.2-23.5 9.7L423.8 306.9c-12.2-1.4-24.5-2-36.8-2-73.2 0-146.4 24.1-206.5 72.3a33.23 33.23 0 00-2.7 49.4l181.7 181.7-215.4 215.2a15.8 15.8 0 00-4.6 9.8l-3.4 37.2c-.9 9.4 6.6 17.4 15.9 17.4.5 0 1 0 1.5-.1l37.2-3.4c3.7-.3 7.2-2 9.8-4.6l215.4-215.4 181.7 181.7c6.5 6.5 15 9.7 23.5 9.7 9.7 0 19.3-4.2 25.9-12.4 56.3-70.3 79.7-158.3 70.2-243.4l161.1-161.1c12.9-12.8 12.9-33.8 0-46.8zM666.2 549.3l-24.5 24.5 3.8 34.4a259.92 259.92 0 01-30.4 153.9L262 408.8c12.9-7.1 26.3-13.1 40.3-17.9 27.2-9.4 55.7-14.1 84.7-14.1 9.6 0 19.3.5 28.9 1.6l34.4 3.8 24.5-24.5L608.5 224 800 415.5 666.2 549.3z" />
          </svg>
        )}
        <style jsx>
          {`
            .pinIcon {
              display: inline-flex;
              padding: 3px;
              border: 1px solid transparent;
            }
            .pinIcon:hover {
              border: 1px solid #ccc;
              border-radius: 2px;
            }
            .pinIcon svg {
              width: 1em;
              height: 1em;
            }
          `}
        </style>
      </span>
    </Tooltip>
  );
}

export default PinToFav;
