/* eslint-disable prefer-destructuring */
export const uniqBy = (list, field) => {
  const uniq = [];
  const seen = {};
  (list || []).forEach(el => {
    const key = typeof field === 'function' ? field(el) : el[field];
    if (seen[key]) return;
    seen[key] = true;
    uniq.push(el);
  });
  return uniq;
};

export const sortBy = (list, field) => {
  const newList = [...list];
  newList.sort((a, b) => a[field] - b[field]);
  return newList;
};

export const groupBy = (list, field) => {
  const grouped = {};
  (list || []).forEach(el => {
    const key = typeof field === 'function' ? field(el) : el[field];
    grouped[key] = grouped[key] || [];
    grouped[key].push(el);
  });
  return grouped;
};

export const getUniqUsers = users => uniqBy(users, 'name');

/* eslint-disable no-mixed-operators */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-bitwise */
export const lightenColor = (color, percent) => {
  if (color.startsWith('#')) color = color.substring(1);
  const num = parseInt(color, 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const B = ((num >> 8) & 0x00ff) + amt;
  const G = (num & 0x0000ff) + amt;

  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
    (G < 255 ? (G < 1 ? 0 : G) : 255)
  )
    .toString(16)
    .slice(1)}`;
};

export const getBaseRouteParams = query => {
  const { language, deskSlug } = query;
  if (!(process.env.BASE_ROUTE_REGEX || '').includes(':deskSlug')) {
    // custom domain, no slug
    return { language };
  }
  return { language, deskSlug };
};

export const getBasePath = (query, lang = true) => {
  const { language, deskSlug } = query;
  const baseRoute = (process.env.BASE_ROUTE_REGEX || '').replace(/:deskSlug\(.*?\)/, deskSlug);
  return `${baseRoute || ''}${lang ? `/${language}` : ''}`;
};

export const getBaseUrl = (query, lang = true) => `${query.origin}${getBasePath(query, lang)}`;

const extractHostname = url => {
  if (!url) return '';

  let hostname;

  // find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  // find & remove port number
  hostname = hostname.split(':')[0];
  // find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
};

export const getLinkRel = link => {
  const hostname = extractHostname(link);
  if (hostname.includes('infoset.app/')) return 'noopener';
  return 'nofollow noreferrer noopener ugc';
};

export const encodeHtml = str => {
  if (!str) return '';
  return str
    .replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
};
