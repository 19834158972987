import { encodeHtml } from '../utils';

const avatarColors = [
  '#d29242',
  '#ae7232',
  '#457cd5',
  '#2f5db2',
  '#59b141',
  '#479236',
  '#213cb0',
  '#3351d3',
  '#ca4146',
  '#a9313f',
  '#212121',
  '#666666',
];

function getRandomColor(str) {
  if (str == null) return avatarColors[0];

  let hash = 0;

  if (str.length === 0) return avatarColors[0];

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash;
  }
  hash = Math.abs(hash);
  return avatarColors[hash % avatarColors.length];
}

function Avatar({ user, text, bgColor = 'white', size = 32 }) {
  const hasAvatar = user && user.avatarUrl;
  if (!text && !hasAvatar) {
    text = user?.name[0] || 'A';
  }
  return (
    <div className="avatar">
      {text || null}
      <style jsx>
        {`
          .avatar {
            width: ${size}px;
            height: ${size}px;
            border-radius: 50%;
            color: white;
            ${hasAvatar
              ? `background: url(${encodeHtml(user.avatarUrl)}) no-repeat center, ${
                  user?.name ? getRandomColor(user.name) : '#C5CCD3'
                };`
              : user?.name
              ? `background: ${getRandomColor(user.name)};`
              : 'background: #C5CCD3;'}
            box-shadow: 0 0 0 2px ${bgColor};
            font-size: ${size / 2}px;
            text-align: center;
            line-height: ${size}px;
            background-size: cover;
            flex: none;
            z-index: 3;
          }
          .avatar ~ :global(.avatar) {
            margin-left: -13px;
          }
          .avatar:nth-of-type(2) {
            z-index: 2;
            opacity: 0.7;
          }
          .avatar:nth-of-type(3) {
            z-index: 1;
            opacity: 0.4;
          }
        `}
      </style>
    </div>
  );
}

export default Avatar;
