import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from '../i18n';
import { Router } from '../routes';
import { getBasePath, getLinkRel } from '../utils';
import fetch from '../utils/fetch';
import LANGUAGES_LIST from '../utils/languageData';
import GlobalContext from './GlobalContext';
import ArticleCard from './articleCard';
import Link from './link';

function Nav() {
  const { t } = useTranslation(['common']);
  const { desk, query } = useContext(GlobalContext);
  const [searchQuery, setSearchQuery] = useState(query.q);
  const searchQueryRef = useRef(searchQuery);
  useEffect(() => {
    searchQueryRef.current = searchQuery;
  }, [searchQuery]);

  const [searchResults, setSearchResults] = useState([]);
  const inputRef = useRef(null);

  const router = useRouter();
  const showSubtitle = !['/collection', '/article'].includes(router.pathname);

  const highlightWords = searchQuery?.split(/\s+/i).filter(word => word.length) || [];

  const search = () => {
    if (!searchQueryRef.current || searchQueryRef.current.length < 3) {
      setSearchResults([]);
      return;
    }
    fetch('/articles/search', {
      query: {
        q: searchQueryRef.current,
        language: query.language,
        desk: desk.slug,
        limit: 5,
      },
    })
      .then(resp => resp.json())
      .then(articles => {
        setSearchResults(articles);
      })
      .catch(() => {});
  };
  const debouncedSearch = useMemo(() => debounce(search, 100), []);

  useEffect(() => {
    if (router.pathname.includes('/search')) {
      return;
    }
    // quick search if it's not the full search results page
    debouncedSearch(searchQuery);
  }, [searchQuery, router.pathname]);

  const clearSearch = useCallback((focus = true) => {
    setSearchQuery(undefined);
    setSearchResults([]);
    inputRef.current.value = '';
    if (focus) {
      inputRef.current.focus(); // focus back
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      if (searchQueryRef.current) {
        // clear search on navigation
        clearSearch(false);
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  const onSearchInputKeyDown = useCallback(
    e => {
      if (e.key === 'Escape' && !!searchResults?.length) {
        setSearchResults([]);
      }
    },
    [searchResults],
  );

  const onSearchSubmit = useCallback(e => {
    e?.preventDefault();
    if (!e.target.q.value) {
      Router.pushRoute(getBasePath(query));
    } else {
      Router.pushRoute(`${getBasePath(query)}/search?q=${encodeURIComponent(e.target.q.value)}`);
    }
  }, []);

  const onChangeLanguage = useCallback(e => {
    // find from alternates, or navigate home
    const newLang = e.target.value;
    const alternates = Array.from(document.head.querySelectorAll('link[rel="alternate"]'));
    const alternate = alternates.find(alt => alt.attributes.hreflang.value === newLang);
    let newRoute;
    if (alternate) {
      newRoute = alternate.attributes['data-path'].value;
    } else {
      newRoute = `${getBasePath(query, false)}/${newLang}`;
    }
    if (typeof window !== 'undefined' && window.source) {
      newRoute += `?source=${window.source}`;
    }
    Router.push(newRoute);
  }, []);

  return (
    <header className="px">
      <div className="content">
        <div className="logoRow">
          <Link route="home">
            {desk.logoUrl ? (
              <a style={{ fontSize: 0 }}>
                <img src={desk.logoUrl} className="logo" alt="" />
              </a>
            ) : (
              <a className="title">{desk.languageFields.title}</a>
            )}
          </Link>
          <div className="links">
            {!!desk.homepage?.length && (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a
                target="_blank"
                rel={getLinkRel(desk.homepage)}
                href={desk.homepage}
                className="hoverable homeUrl"
              >
                <i className="fas fa-external-link-alt" />
                <span>{t('goToApp')}</span>
              </a>
            )}
            {desk.languages.length > 1 && (
              <span className="localePickerCtr hoverable">
                <select className="localePicker" onChange={onChangeLanguage} value={query.language}>
                  {desk.languages.map(l => (
                    <option key={l.language} value={l.language}>
                      {LANGUAGES_LIST[l.language]?.nativeName || l.language.toUpperCase()}
                    </option>
                  ))}
                </select>
              </span>
            )}
          </div>
        </div>
        {showSubtitle && <h1 className="subtitle">{desk.languageFields.subtitle}</h1>}
        <form onSubmit={onSearchSubmit} action="search" autoComplete="off" className="searchForm">
          <input
            type="text"
            autoComplete="off"
            placeholder={t('headerSearchPlaceholder')}
            name="q"
            ref={inputRef}
            value={searchQuery}
            onKeyDown={onSearchInputKeyDown}
            onChange={e => setSearchQuery(e.target.value)}
          />
          <button type="submit" className="transparent searchIcon" tabIndex={-1}>
            <i className="fas fa-search" />
          </button>
          {!!searchQuery && (
            <button
              type="button"
              className="transparent clearIcon"
              onClick={() => clearSearch(true)}
              tabIndex={-1}
            >
              <i className="fas fa-times" />
            </button>
          )}

          {searchQuery && !!searchResults?.length && (
            <div className="searchResults">
              {searchResults.map(article => (
                <ArticleCard
                  {...article}
                  key={article._id}
                  highlightWords={highlightWords}
                  forQuickSearch
                />
              ))}
            </div>
          )}
        </form>
      </div>
      <style global jsx>
        {`
          .localePickerCtr {
            position: relative;
            margin-left: 20px;
            cursor: pointer;
            z-index: 0;
          }
          .localePickerCtr::before {
            content: '\f57d';
            font-size: 1.1em;
            font-family: 'Font Awesome 5 Free';
            font-weight: 600;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
          }
          .localePickerCtr::after {
            content: '\f107';
            font-size: 1.1em;
            font-family: 'Font Awesome 5 Free';
            font-weight: 600;
            position: absolute;
            right: 2px;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
          }
          .localePicker {
            font-size: inherit;
            font-weight: inherit;
            appearance: none;
            border: none;
            outline: none;
            color: white;
            background-color: transparent;
            padding: 0 18px;
            cursor: pointer;
            top: 1px;
          }
        `}
      </style>
      <style jsx>
        {`
          header {
            ${desk.headerImgUrl && `background-image: url('${desk.headerImgUrl}');`}
            background-size: cover;
            background-position: center;
            background-color: ${desk.color};
            color: white;
            padding-top: 30px;
            padding-bottom: 30px;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
            margin-bottom: 7px;
          }
          .logoRow {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .logoRow .links a:not(:first-child) {
            margin-left: 10px;
          }
          .logo {
            max-width: 360px;
            max-height: 35px;
          }
          .homeUrl span {
            margin-left: 3px;
          }
          .homeUrl i {
            font-size: 0.9em;
          }
          .title {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.2;
            margin: 0;
          }
          .subtitle {
            font-weight: 200;
            margin: 15px 0 0;
            font-size: 28px;
          }
          .searchForm {
            position: relative;
            margin-top: 20px;
          }
          .searchForm input {
            width: 100%;
            padding: 20px 35px 21px 60px;
            background: rgba(255, 255, 255, 0.2);
            border: none;
            outline: none;
            color: rgba(255, 255, 255, 0.7);
            font-size: 18px;
            border-radius: 4px;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
            transition: background 0.4s, box-shadow 0.2s;
          }
          .searchForm input::placeholder {
            color: inherit;
          }
          .searchForm input:focus {
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.14);
            background: white;
            color: #3a3c4c;
          }
          .searchForm input:focus ~ button {
            color: #3a3c4c;
          }
          .searchForm .searchIcon,
          .searchForm .clearIcon {
            position: absolute;
            top: 50%;
            opacity: 0.9;
            transform: translateY(-50%);
            color: rgba(255, 255, 255, 0.7);
            cursor: pointer;
            padding: 0;
            margin: 0;
          }
          .searchForm .searchIcon {
            font-size: 22px;
            left: 23px;
          }
          .searchForm .clearIcon {
            font-size: 20px;
            right: 15px;
          }
          .logoRow .links {
            font-size: 13px;
            font-weight: 500;
            display: flex;
            flex-flow: row nowrap;
          }
          .searchResults {
            position: absolute;
            z-index: 10;
            width: 100%;
            top: 100%;
            margin-top: 10px;
            background: white;
            border-radius: 4px;
            box-shadow: 0 60px 60px 0 #fff;
          }
          @media screen and (max-width: 767px) {
            .logoRow {
              padding-top: 18px;
            }
            .logoRow .links {
              position: absolute;
              top: 20px;
              right: 20px;
            }
          }
        `}
      </style>
    </header>
  );
}

export default Nav;
