import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';

const noThrow = (fn) => {
  try {
    fn();
  } catch (ignored) {}
};

export const initGA = (id) => {
  ReactGA.initialize(id);
};

export const logPageView = () => {
  const page = window.location.pathname;
  if (window.GA_INITIALIZED) {
    noThrow(() => {
      ReactGA.set({ page });
      ReactGA.pageview(page);
    });
  }
  if (window.analytics) {
    // segment
    noThrow(() => window.analytics.page(page));
  }
  if (window.amplitude) {
    noThrow(() => window.amplitude.logEvent('pageview', { page }));
  }
};

export const identify = (userId, otherFields = {}) => {
  window.identifyUserId = userId;
  window.identifyFields = window.identifyFields || {};
  window.identifyFields = { ...window.identifyFields, ...otherFields };
  if (window.GA_INITIALIZED) {
    noThrow(() => ReactGA.set({ userId }));
  }
  if (window.analytics) {
    noThrow(() => {
      window.analytics.identify(userId, otherFields);
    });
  }
  if (window.amplitude) {
    noThrow(() => {
      window.amplitude.getInstance().setUserId(userId);
      const amplitudeIdentity = Object.keys(otherFields).reduce(
        (xs, x) => xs.set(x, otherFields[x]),
        new window.amplitude.Identify(),
      );
      window.amplitude.getInstance().identify(amplitudeIdentity);
    });
  }
};

export const unidentify = () => {
  if (window.GA_INITIALIZED) {
    noThrow(() => ReactGA.set({ userId: null }));
  }
  if (window.analytics) {
    noThrow(() => {
      window.analytics.reset();
    });
  }
  if (window.amplitude) {
    noThrow(() => {
      window.amplitude.getInstance().setUserId(null);
      const unsetAmplitudeIdentity = Object.keys(window.identifyFields).reduce(
        (xs, x) => xs.unset(x),
        new window.amplitude.Identify(),
      );
      window.amplitude.getInstance().identify(unsetAmplitudeIdentity);
      window.amplitude.getInstance().regenerateDeviceId();
    });
  }
};

export const logEventGA = (category, action) => {
  noThrow(() => ReactGA.event({ category, action }));
};

export const logEventSentry = (event) => {
  Sentry.captureEvent(event);
};

export const logExceptionGA = (description, fatal = false) => {
  noThrow(() => ReactGA.exception({ description, fatal }));
};

export const logExceptionSentry = (exception) => {
  Sentry.captureException(exception);
};
