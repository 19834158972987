const NextI18Next = require('next-i18next').default;
const path = require('path');

const defaultLanguage = 'en';
const availableLanguages = ['en', 'tr', 'fr', 'it', 'de', 'es', 'ru', 'ar', 'pt', 'az', 'el', 'ro'];

const NextI18NextInstance = new NextI18Next({
  cleanCode: true, // EN -> en
  defaultNS: 'common',
  fallbackLng: false,
  defaultLanguage: 'en',
  otherLanguages: availableLanguages.filter(l => l !== defaultLanguage),
  localeSubpaths: {},
  localePath: path.resolve('./static/locales'),
  browserLanguageDetection: false,
  serverLanguageDetection: false,
  interpolation: {
    // eslint-disable-next-line no-unused-vars
    format(value, format, lng) {
      if (format === 'darkText') return `<span class="darkText">${value}</span>`;
      return value;
    },
    escapeValue: false,
  },
});

NextI18NextInstance.availableLanguages = availableLanguages;

module.exports = NextI18NextInstance;
