import Head from 'next/head';
import { useTranslation } from '../i18n';

function getTitleForStatus(status) {
  if ([400, 401, 404, 500].includes(status)) return `error${status}Title`;
  return 'errorUnexpectedTitle';
}

function getMessageForStatus(status) {
  if ([400, 401, 404, 500].includes(status)) return `error${status}Message`;
  return 'errorUnexpectedMessage';
}

function getSubForStatus(status) {
  if ([400, 401, 404, 500].includes(status)) return `error${status}Sub`;
  return 'errorUnexpectedSub';
}

export const i18nNs = ['error'];

function ErrorPage({ desk, statusCode }) {
  const { t } = useTranslation(i18nNs);
  statusCode = statusCode || 404;
  const title = getTitleForStatus(statusCode);
  const message = getMessageForStatus(statusCode);
  if (desk) {
    // partial view inside layout
    const sub = getSubForStatus(statusCode);
    return (
      <div className="root">
        <div className="title">{t(title, { defaultValue: 'Unexpected Error' })}</div>
        <div className="message">{t(message, { defaultValue: 'An error occurred' })}</div>
        <div>{t(sub, { defaultValue: 'An error occurred, please try again later' })}</div>
        <style jsx>
          {`
            .title {
              color: #e04d50;
              text-transform: uppercase;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: 0.1em;
            }
            .message {
              margin: 0.5em 0;
              font-size: 1.5rem;
            }
          `}
        </style>
      </div>
    );
  }
  // full-page error screen, there's no desk
  return (
    <div className="root">
      <Head>
        <title key="title">{t(title)}</title>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Poppins:400,500&display=swap"
        />
      </Head>
      <a href={`https://${process.env.HELPDESK_DOMAIN}`} className="logoContainer">
        <img src="https://cdn.infoset.app/logo.png" alt="infoset" />
      </a>
      <h1>{t('errorCodeHeader', { statusCode, defaultValue: 'Unexpected server error' })}</h1>
      <h2>{t(title, { defaultValue: 'Unexpected error' })}</h2>
      <p>{t(message, { defaultValue: 'An error occurred, please try again' })}</p>
      <a href={`https://${process.env.HELPDESK_DOMAIN}`}>{t('Go to homepage')}</a>
      <style jsx>
        {`
          :global(html) {
            box-sizing: border-box;
            height: 100%;
            min-height: 100%;
            font-size: 1em;
            line-height: 1.4;
          }
          :global(body) {
            margin: 0;
            height: 100%;
            min-height: 100%;
            font-family: Poppins, 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 16px;
            line-height: 1.4;
            color: #212121;
            -webkit-font-smoothing: antialiased;
            text-align: center;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
          }
          .root {
            max-width: 980px;
            padding: 40px;
            margin: 0 auto;
          }
          .logoContainer {
            font-size: 0;
          }
          .logoContainer img {
            max-height: 32px;
            border-radius: 6px;
          }
          h1 {
            font-size: 1rem;
            color: #e04d50;
            letter-spacing: 0.1em;
          }
          h2 {
            margin: 0.5em 0;
            font-size: 2rem;
            font-weight: normal;
          }
        `}
      </style>
    </div>
  );
}

ErrorPage.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode, namespacesRequired: i18nNs };
};

export default ErrorPage;
