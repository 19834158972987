import { useContext } from 'react';
import { Link as RouteLink } from '../routes';
import { getBaseRouteParams } from '../utils';
import GlobalContext from './GlobalContext';

function Link(props) {
  const { query } = useContext(GlobalContext);
  return <RouteLink {...props} params={{ ...props.params, ...getBaseRouteParams(query) }} />;
}

export default Link;
