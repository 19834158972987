import Head from 'next/head';
import { useContext, useEffect } from 'react';
import Footer from '../components/footer';
import GlobalContext from '../components/GlobalContext';
import Header from '../components/header';
import { initGA, logPageView } from '../utils/analytics';
import theme from './theme';

function getImageType(url) {
  if (/\.png$/i.test(url)) return 'image/png';
  if (/\.jpe?g$/i.test(url)) return 'image/jpeg';
  if (/\.gif$/i.test(url)) return 'image/gif';
  if (/\.svg$/i.test(url)) return 'image/svg+xml';
  if (/\.ico$/i.test(url)) return 'image/x-icon';
  if (/\.tiff?$/i.test(url)) return 'image/tiff';
  return undefined;
}

function Layout({ children }) {
  const { desk, query = {} } = useContext(GlobalContext);
  const { integrations } = desk;

  useEffect(() => {
    // track with GA on mount
    if (integrations?.googleAnalytics?.enabled && integrations.googleAnalytics.id) {
      if (!window.GA_INITIALIZED) {
        initGA(integrations.googleAnalytics.id);
        window.GA_INITIALIZED = true;
      }
    }
    logPageView();

    // boot chat widget if given for language
    const langObj =
      desk.languages.find(l => l.language === query.language) ||
      desk.languages.find(l => l.language === desk.defaultLanguage);
    const chatWidget = langObj?.chatWidget;
    if (chatWidget?.apiKey && query.source !== 'widget') {
      const id = 'infoset-chat-widget-script';
      const onLoad = () => {
        if (typeof window.InfosetChat === 'function') {
          try {
            window.InfosetChat('boot', {
              widget: { apiKey: chatWidget.apiKey, ...(chatWidget.settings || {}) },
            });
          } catch (ignored) {}
        }
      };
      const existingScript = document.getElementById(id);
      if (existingScript) {
        existingScript.addEventListener('load', onLoad, false);
      } else {
        const script = document.createElement('script');
        script.id = id;
        script.type = 'text/javascript';
        script.async = true;
        script.src = `${process.env.CHAT_PUBLIC_URL || 'https://cdn.infoset.app/chat/'}icw.js`;
        (
          document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]
        ).appendChild(script);
        script.addEventListener('load', onLoad, false);
      }
    }
  }, []);

  const faviconUrl = desk.faviconUrl || 'https://cdn.infoset.app/logo.png';

  return (
    <main className="layout">
      <Head>
        <title key="title">{desk.languageFields.title || ''}</title>
        <link rel="shortcut icon" href={faviconUrl} type={getImageType(faviconUrl)} />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.15.2/css/all.css" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Poppins:200,400,500,600&display=swap"
        />
        <meta name="x-infoset-desk" content="1" />
      </Head>
      <Header />
      <div className="container px">
        <div className="content">{children}</div>
      </div>
      <Footer />
      <style jsx global>
        {`
          html {
            box-sizing: border-box;
            height: 100%;
            min-height: 100%;
            font-size: 1em;
            line-height: 1.4;
          }
          body {
            margin: 0;
            height: 100%;
            min-height: 100%;
            font-family: Poppins, 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 16px;
            line-height: 1.6;
            background: ${theme.bgGray};
            color: #212121;
            -webkit-font-smoothing: antialiased;
          }
          button.transparent {
            border: none;
            outline: none;
            background: transparent;
          }
          img,
          video {
            max-width: 100%;
          }
          img {
            image-rendering: -webkit-optimize-contrast;
          }
          code {
            padding: 0.3em;
            border-radius: 4px;
            border: 1px solid rgb(232, 235, 237);
            background: rgb(244, 247, 250);
            font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace;
            font-size: 80%;
          }
          pre {
            white-space: pre-wrap;
          }
          pre code {
            padding: 1em;
            background: rgb(244, 247, 250) !important;
            border-radius: 4px;
            border: 1px solid rgb(232, 235, 237);
            font-size: 85%;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            font-size: 14px;
          }
          table tr {
            position: relative;
            border-bottom: 1px solid #c5ccd3;
          }
          table th {
            background-color: #fafafa;
            vertical-align: top;
            border: 1px solid #c5ccd3;
            position: relative;
            padding: 4px 8px;
            text-align: left;
            min-width: 100px;
          }
          table td {
            position: relative;
            vertical-align: top;
            border: 1px solid #c5ccd3;
            padding: 4px 8px;
            text-align: left;
            min-width: 100px;
          }
          .iframeVideoCtr {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
          }
          .iframeVideoCtr iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          iframe {
            border: none;
            margin: 0;
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            margin-top: 1.3em;
            line-height: 1.24;
            font-weight: 500;
          }
          #__next {
            height: 100%;
            min-height: 100%;
          }
          *,
          *:before,
          *:after {
            box-sizing: inherit;
          }
          a {
            text-decoration: none;
            color: inherit;
          }
          .hoverable:hover {
            opacity: 0.8;
          }
          .underlined {
            text-decoration: underline;
          }
          .underlined.hoverable:hover {
            text-decoration: none;
          }
          .px {
            padding-left: 40px;
            padding-right: 40px;
          }
          .content {
            max-width: 100%;
            width: 900px;
            margin-left: auto;
            margin-right: auto;
          }
          .darkText {
            color: ${theme.darkText};
          }
          .lightText {
            color: ${theme.lightText};
          }
          @media screen and (max-width: 767px) {
            .px {
              padding-left: 20px;
              padding-right: 20px;
            }
          }

          // nprogress styles
          #nprogress {
            pointer-events: none;
          }

          #nprogress .bar {
            background: #fff;
            position: fixed;
            z-index: 1031;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
          }

          /* Fancy blur effect */
          #nprogress .peg {
            display: block;
            position: absolute;
            right: 0px;
            width: 100px;
            height: 100%;
            box-shadow: 0 0 10px #fff, 0 0 5px #fff;
            opacity: 1;
            transform: rotate(3deg) translate(0px, -4px);
          }

          /* Remove these to get rid of the spinner */
          #nprogress .spinner {
            display: block;
            position: fixed;
            z-index: 1031;
            top: 15px;
            right: 15px;
          }

          #nprogress .spinner-icon {
            width: 18px;
            height: 18px;
            box-sizing: border-box;
            border: solid 2px transparent;
            border-top-color: #fff;
            border-left-color: #fff;
            border-radius: 50%;
            animation: nprogress-spinner 400ms linear infinite;
          }

          .nprogress-custom-parent {
            overflow: hidden;
            position: relative;
          }

          .nprogress-custom-parent #nprogress .spinner,
          .nprogress-custom-parent #nprogress .bar {
            position: absolute;
          }

          @keyframes nprogress-spinner {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
      <style jsx>
        {`
          .layout {
            display: flex;
            flex-flow: column nowrap;
            min-height: 100%;
          }
          .container {
            padding-top: 35px;
            padding-bottom: 35px;
            flex: 1;
          }
        `}
      </style>
    </main>
  );
}

export default Layout;
