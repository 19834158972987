/* eslint-disable prefer-destructuring */
import * as Sentry from '@sentry/browser';
import 'highlight.js/styles/github.css';
import App from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import GlobalContext from '../components/GlobalContext';
import { appWithTranslation, availableLanguages, i18n } from '../i18n';
import Layout from '../layouts/main';
import { getBasePath } from '../utils';
import { logExceptionSentry } from '../utils/analytics';
import fetch from '../utils/fetch';
import ErrorPage, { i18nNs as errorI18nNs } from './_error';

if (process.env.IS_ONPREMISE) {
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
}

const beforeSend = (event, hint) => {
  const error = hint.originalException || hint.syntheticException;
  if (error && typeof error.message === 'string' && error.message.includes('ResizeObserver')) {
    return null;
  }
  console.error(error);
  return event;
};

Sentry.init({
  dsn: 'https://b08f3a4b7e4b47169dff0c1253abc5de@sentry.infoset.app/2',
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV !== 'development',
  beforeSend,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    "t is not a function. (In 't()', 't' is undefined)",
  ],
});

const redirect = (ctx, path) => {
  if (ctx.res) {
    ctx.res.writeHead(302, { Location: path });
    ctx.res.end();
  } else {
    Router.push(path);
  }
};

let routeChangeTimeout;
Router.events.on('routeChangeStart', () => {
  clearTimeout(routeChangeTimeout);
  routeChangeTimeout = setTimeout(() => {
    NProgress.start();
    routeChangeTimeout = null;
  }, 300);
});
Router.events.on('routeChangeComplete', () => {
  clearTimeout(routeChangeTimeout);
  if (NProgress.isStarted()) {
    NProgress.done();
  }
});
Router.events.on('routeChangeError', () => {
  clearTimeout(routeChangeTimeout);
  if (NProgress.isStarted()) {
    NProgress.done();
  }
});

const getDefaultPageProps = async ctx => {
  const slug = ctx.query.deskSlug;
  let desk;
  let domain;
  if (ctx.req) {
    const useSsl = ctx.req.header('x-use-ssl');
    const proto = (useSsl === 'on' && 'https') || (useSsl === 'off' && 'http') || ctx.req.protocol;
    ctx.query.origin = `${proto}://${ctx.req.get('host')}`;
    domain = ctx.req.get('host').split(':')[0];
  } else {
    ctx.query.origin = window.location.origin;
    domain = window.location.hostname;
  }

  if (slug) {
    desk = await fetch('/desk', { query: { desk: slug } }).then(resp => resp.json());
  } else {
    desk = await fetch('/desk', { query: { domain } }).then(resp => resp.json());
    if (!desk) {
      // no desk with this domain, redirect to infoset.app
      redirect(ctx, 'https://infoset.app');
      throw new Error(`Domain is not a registered custom domain with infoset.help: ${domain}`);
    }
  }
  // make sure necessary stuff is there in case of an error page
  if (!ctx.query.deskSlug) ctx.query.deskSlug = desk.slug;
  if (!ctx.query.language) ctx.query.language = desk.defaultLanguage;
  ctx.desk = desk;
  ctx.domain = domain;
  return { desk, query: ctx.query };
};

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    if (ctx.req && ctx.req.i18n) {
      // SSR, set initial language from query params
      const lang = ctx.query?.language;
      if (lang && availableLanguages.includes(lang)) {
        ctx.req.i18n.options.defaultLanguage = lang;
        ctx.req.i18n.options.fallbackLng = lang;
        ctx.req.i18n.options.lng = lang;
        ctx.req.i18n.language = lang;
        ctx.req.i18n.languages = [lang];
        await ctx.req.i18n.changeLanguage(lang);
        const resBundle = ctx.req.i18n.getResourceBundle(lang, 'common');
        if (!resBundle || !Object.keys(resBundle).length) {
          ctx.req.i18n.addResourceBundle(
            lang,
            'common',
            ctx.req.i18n.getResourceBundle('en', 'common'),
          );
          ctx.req.i18n.addResourceBundle(
            lang,
            'error',
            ctx.req.i18n.getResourceBundle('en', 'error'),
          );
        }
      }
      //  else {
      //   await ctx.req.i18n.changeLanguage(ctx.req.i18n.options.defaultLanguage);
      // }
    }

    let pageProps = {};
    try {
      const lang = ctx.query?.language;
      if (lang && availableLanguages.includes(lang)) {
        i18n.options.defaultLanguage = lang;
        i18n.options.fallbackLng = lang;
        i18n.options.lng = lang;
        i18n.language = lang;
        i18n.languages = [lang];
        await i18n.changeLanguage(lang);
      }
      pageProps = { ...(await getDefaultPageProps(ctx)) };
      if (Component.getInitialProps) {
        pageProps = { ...pageProps, ...(await Component.getInitialProps(ctx)) };
      }
    } catch (err) {
      if (process.env.NODE_ENV === 'development') {
        console.log(Component, err, err.stack);
      }
      if (err.status) {
        if (err.status >= 500) {
          logExceptionSentry(err); // it's a server error, log it
        }
        if (ctx.res) {
          ctx.res.statusCode = err.status;
        }
        pageProps.statusCode = err.status;
      }
      if (!pageProps.namespacesRequired) {
        // it is an error and not populated, so populate with error
        pageProps.namespacesRequired = errorI18nNs;
      }
    }
    if (!pageProps.desk) {
      pageProps.statusCode = 404;
    }

    // redirect to default language if it doesn't exist
    if (ctx.query.pagePath === '/redirectDefaultLanguage' && ctx.desk) {
      const path = getBasePath({
        deskSlug: ctx.desk.slug,
        language: ctx.desk.defaultLanguage,
      });
      redirect(ctx, path);
      return { pageProps };
    }

    return { pageProps };
  }

  componentDidMount() {
    const { pageProps } = this.props;
    if (typeof window !== 'undefined') {
      if (!window.initialSource) {
        window.initialSource = pageProps.query?.source;
      } else {
        pageProps.query = pageProps.query || {};
        pageProps.query.source = window.initialSource;
      }
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;
    const { desk, query = {} } = pageProps;
    console.error('desk', desk);
    console.error('pageProps', pageProps);

    if (desk) {
      desk.languageFields =
        desk.languages.find(l => l.language === query.language) ||
        desk.languages.find(l => l.language === desk.defaultLanguage);
    }
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const ctxValues = { desk, query };
    if (pageProps.statusCode) {
      if (desk) {
        return (
          <GlobalContext.Provider value={ctxValues}>
            <Layout>
              <ErrorPage desk={desk} statusCode={pageProps.statusCode} />
            </Layout>
          </GlobalContext.Provider>
        );
      }
      return <ErrorPage statusCode={pageProps.statusCode} />;
    }
    return (
      <GlobalContext.Provider value={ctxValues}>
        <Layout>
          {/* don't display header/footer etc inside widget, just display the main part */}
          {query?.source && (
            <script>{`try { window.source = \`${query.source}\`; } catch (_) {}`}</script>
          )}
          {/* custom widget CSS */}
          {((typeof window !== 'undefined' ? window.source : false) || query?.source) ===
            'widget' && (
            <style jsx global>
              {`
                .layout > header {
                  padding-top: 12px !important;
                  padding-bottom: 12px !important;
                  margin-bottom: 0 !important;
                }
                .layout > header .logoRow {
                  display: none !important;
                }
                .layout > header .subtitle {
                  display: none !important;
                }
                .layout > header form {
                  margin-top: 0 !important;
                }
                .layout > header form input {
                  padding-top: 12px !important;
                  padding-bottom: 12px !important;
                }
                .root > .content {
                  padding-top: 32px;
                }
                .layout .collections {
                  padding: 20px !important;
                }
                .root .collectionContainer {
                  padding-left: 10px !important;
                  padding-right: 10px !important;
                }
                .breadcrumbs {
                  padding-left: 20px;
                  padding-right: 5px;
                }
                .layout > .container {
                  padding: 0 !important;
                  padding-bottom: 2px !important;
                }
              `}
            </style>
          )}
          <Component {...pageProps} />
        </Layout>
      </GlobalContext.Provider>
    );
  }
}

export default appWithTranslation(MyApp);
