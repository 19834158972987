/* eslint-disable no-cond-assign */
import Fingerprint2 from 'fingerprintjs2';
import { useEffect, useState } from 'react';

// Disqus loader
if (typeof window !== 'undefined') {
  (function main(window, document) {
    const extendObj = function fn(defaults, options) {
      let prop;
      const extended = {};
      for (prop in defaults) {
        // eslint-disable-next-line max-len
        if (Object.prototype.hasOwnProperty.call(defaults, prop)) {
          extended[prop] = defaults[prop];
        }
      }

      for (prop in options) {
        if (Object.prototype.hasOwnProperty.call(options, prop)) {
          extended[prop] = options[prop];
        }
      }

      return extended;
    };
    const getOffset = function fn(el) {
      const rect = el.getBoundingClientRect();
      // eslint-disable-next-line max-len
      return {
        top: rect.top + document.body.scrollTop,
        left: rect.left + document.body.scrollLeft,
      };
    };
    const loadScript = function fn(url, callback) {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.setAttribute('data-timestamp', +new Date());
      script.addEventListener('load', () => {
        if (typeof callback === 'function') {
          callback();
        }
      });
      (document.head || document.body).appendChild(script);
    };
    const throttle = function fn(a, b) {
      let c, d;
      return function infn() {
        const e = this,
          // eslint-disable-next-line
          f = arguments,
          g = +new Date();
        c && g < c + a
          ? (clearTimeout(d),
            (d = setTimeout(() => {
              c = g;
              b.apply(e, f);
            }, a)))
          : ((c = g), b.apply(e, f));
      };
    };

    let throttleTO = false;
    let laziness = false;
    let disqusConfig = false;
    let scriptUrl = false;

    let scriptStatus = 'unloaded';
    let instance = false;

    const init = function fn() {
      if (
        !instance ||
        !document.body.contains(instance) ||
        instance.disqusLoaderStatus === 'loaded'
      ) {
        return true;
      }

      const winST = window.pageYOffset,
        offset = getOffset(instance).top;

      // if the element is too far below || too far above
      if (
        offset - winST > window.innerHeight * laziness ||
        winST - offset - instance.offsetHeight - window.innerHeight * laziness > 0
      ) {
        return true;
      }

      const tmp = document.getElementById('disqus_thread');
      if (tmp) tmp.removeAttribute('id');
      instance.setAttribute('id', 'disqus_thread');
      instance.disqusLoaderStatus = 'loaded';

      if (scriptStatus === 'loaded') {
        window.DISQUS.reset({ reload: true, config: disqusConfig });
      } else {
        // unloaded | loading
        window.disqus_config = disqusConfig;
        if (scriptStatus === 'unloaded') {
          scriptStatus = 'loading';
          loadScript(scriptUrl, () => {
            scriptStatus = 'loaded';
          });
        }
      }
    };

    window.addEventListener('scroll', throttle(throttleTO, init));
    window.addEventListener('resize', throttle(throttleTO, init));

    window.disqusLoader = function fn(element, options) {
      options = extendObj(
        {
          laziness: 1,
          throttle: 250,
          scriptUrl: false,
          disqusConfig: false,
        },
        options,
      );

      laziness = options.laziness + 1;
      throttleTO = options.throttle;
      disqusConfig = options.disqusConfig;
      scriptUrl = scriptUrl === false ? options.scriptUrl : scriptUrl; // set it only once

      if (typeof element === 'string') instance = document.querySelector(element);
      else if (typeof element.length === 'number') [instance] = element;
      else instance = element;

      if (instance) instance.disqusLoaderStatus = 'unloaded';

      init();
    };
  }(window, document));
}

export const murmurHash3 = (key, seed) => {
  let h1;
  let h1b;
  let k1;
  let i;
  const remainder = key.length & 3; // key.length % 4
  const bytes = key.length - remainder;
  h1 = seed;
  const c1 = 0xcc9e2d51;
  const c2 = 0x1b873593;
  i = 0;
  while (i < bytes) {
    k1 =
      (key.charCodeAt(i) & 0xff) |
      ((key.charCodeAt(++i) & 0xff) << 8) |
      ((key.charCodeAt(++i) & 0xff) << 16) |
      ((key.charCodeAt(++i) & 0xff) << 24);
    ++i;
    k1 = ((k1 & 0xffff) * c1 + ((((k1 >>> 16) * c1) & 0xffff) << 16)) & 0xffffffff;
    k1 = (k1 << 15) | (k1 >>> 17);
    k1 = ((k1 & 0xffff) * c2 + ((((k1 >>> 16) * c2) & 0xffff) << 16)) & 0xffffffff;

    h1 ^= k1;
    h1 = (h1 << 13) | (h1 >>> 19);
    h1b = ((h1 & 0xffff) * 5 + ((((h1 >>> 16) * 5) & 0xffff) << 16)) & 0xffffffff;
    h1 = (h1b & 0xffff) + 0x6b64 + ((((h1b >>> 16) + 0xe654) & 0xffff) << 16);
  }
  k1 = 0;
  // eslint-disable-next-line default-case
  switch (remainder) {
    case 3:
      k1 ^= (key.charCodeAt(i + 2) & 0xff) << 16;
    // eslint-disable-next-line no-fallthrough
    case 2:
      k1 ^= (key.charCodeAt(i + 1) & 0xff) << 8;
    // eslint-disable-next-line no-fallthrough
    case 1:
      k1 ^= key.charCodeAt(i) & 0xff;
      k1 = ((k1 & 0xffff) * c1 + ((((k1 >>> 16) * c1) & 0xffff) << 16)) & 0xffffffff;
      k1 = (k1 << 15) | (k1 >>> 17);
      k1 = ((k1 & 0xffff) * c2 + ((((k1 >>> 16) * c2) & 0xffff) << 16)) & 0xffffffff;
      h1 ^= k1;
  }
  h1 ^= key.length;
  h1 ^= h1 >>> 16;
  h1 = ((h1 & 0xffff) * 0x85ebca6b + ((((h1 >>> 16) * 0x85ebca6b) & 0xffff) << 16)) & 0xffffffff;
  h1 ^= h1 >>> 13;
  h1 = ((h1 & 0xffff) * 0xc2b2ae35 + ((((h1 >>> 16) * 0xc2b2ae35) & 0xffff) << 16)) & 0xffffffff;
  h1 ^= h1 >>> 16;
  return h1 >>> 0;
};

export const getSimpleUserAgent = () => {
  let osName = 'Unknown OS';
  if (navigator.userAgent.indexOf('Win') !== -1) osName = 'Windows';
  if (navigator.userAgent.indexOf('Mac') !== -1) osName = 'Macintosh';
  if (navigator.userAgent.indexOf('Linux') !== -1) osName = 'Linux';
  if (navigator.userAgent.indexOf('Android') !== -1) osName = 'Android';
  if (navigator.userAgent.indexOf('like Mac') !== -1) osName = 'iOS';
  let browserName = navigator.appName;
  const nAgt = navigator.userAgent;
  let nameOffset;
  let verOffset;
  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    browserName = 'Opera';
  } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    browserName = 'Internet Explorer';
  } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    browserName = 'Chrome';
  } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    browserName = 'Safari';
  } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    browserName = 'Firefox';
  } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
    browserName = nAgt.substring(nameOffset, verOffset);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  return `${osName} - ${browserName}`;
};

export const getFingerprint = async () => {
  if (window.id_fp) return window.id_fp;

  // wait a bit to get better results
  let wait;
  if (window.requestIdleCallback) {
    wait = new Promise(resolve => {
      requestIdleCallback(resolve);
    });
  } else {
    wait = new Promise(resolve => {
      setTimeout(resolve, 500);
    });
  }
  await wait;

  const components = await Fingerprint2.getPromise({
    preprocessor(key, value) {
      if (key === 'userAgent') {
        return getSimpleUserAgent();
      }
      return value;
    },
  });
  const values = components.map(component => component.value);
  const hash = murmurHash3(values.join(''), 31);
  window.id_fp = hash;
  return hash;
};

const pinnedArticleIdSetters = [];

export const usePinnedArticleIds = () => {
  if (typeof window === 'undefined') return [[], () => { }];

  const [pinnedArticleIds, setPinnedArticleIds] = useState(() => {
    const storedValue = localStorage.getItem('pinnedArticleIds');
    try {
      return JSON.parse(storedValue) ?? [];
    } catch {
      return [];
    }
  });

  useEffect(() => {
    localStorage.setItem('pinnedArticleIds', JSON.stringify(pinnedArticleIds ?? []));
    pinnedArticleIdSetters.forEach(setter => setter(pinnedArticleIds));
  }, [pinnedArticleIds]);

  // add & remove setter to the list
  useEffect(() => {
    pinnedArticleIdSetters.push(setPinnedArticleIds);
    return () => {
      const index = pinnedArticleIdSetters.indexOf(setPinnedArticleIds);
      if (index > -1) {
        pinnedArticleIdSetters.splice(index, 1);
      }
    };
  }, []);

  return [pinnedArticleIds, setPinnedArticleIds];
};
