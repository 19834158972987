import originalFetch from 'isomorphic-unfetch';
import ApiError from '../error/ApiError';
import { logExceptionSentry } from './analytics';

function stringifyQueryParams(params) {
  return Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');
}

const fetch = (url, { query, ctx, ...fetchOptions } = {}) => {
  query = query || {};
  if (ctx?.query?.deskSlug) {
    query.desk = query.desk || ctx?.query?.deskSlug;
  }
  // set default language if language is not available on desk
  if (ctx?.desk && query.language && !ctx.desk.languages.find((l) => l.language === query.language)) {
    query.language = ctx.desk.defaultLanguage;
  }
  if (!url.includes('://')) {
    url = `${process.env.API_URL}${url}`;
  }
  if (Object.keys(query).length) {
    url += (url.indexOf('?') === -1 ? '?' : '&') + stringifyQueryParams(query);
  }
  fetchOptions.headers = fetchOptions.headers || {};
  fetchOptions.headers['X-Desk'] = 1; // indicate that request is coming from Infoset desk app

  return originalFetch(url, fetchOptions).then(async (response) => {
    if (!response.ok) {
      let jsonBody = {};
      try {
        jsonBody = await response.json();
        jsonBody.status = response.status;
      } catch (ignored) {}
      const status = jsonBody.status || response.status;
      const err = new ApiError(
        jsonBody.error || response.statusText,
        jsonBody.status || response.status,
        jsonBody.code,
      );
      if (status >= 500) {
        logExceptionSentry(err);
      }
      throw err;
    }
    return response;
  });
};

export default fetch;
