import escapeStringRegexp from 'escape-string-regexp';

const removeAccents = s => {
  let r = (s || '').toLowerCase();
  r = r.replace(/[àáâãäå]/g, 'a');
  r = r.replace(/æ/, 'ae');
  r = r.replace(/ç/, 'c');
  r = r.replace(/[èéêë]/, 'e');
  r = r.replace(/[ıìíîï]/, 'i');
  r = r.replace(/ñ/, 'n');
  r = r.replace(/[òóôõö]/, 'o');
  r = r.replace(/œ/, 'oe');
  r = r.replace(/[ùúûü]/, 'u');
  r = r.replace(/[ýÿ]/, 'y');
  r = r.replace(/ğ/, 'g');
  r = r.replace(/ş/, 's');
  return r;
};

function excerptSearch(text, words, options = {}) {
  options.padding = options.padding || 40;
  options.highlightClass = options.highlightClass || 'highlight';
  options.maxLength = options.maxLength || text.length;

  // sanitize the regex and split back into array
  if (words instanceof Array) {
    words = words.join(' ');
  }
  words = removeAccents(words)
    .replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')
    .split(' ');

  // find search result chunks
  const regex = new RegExp(
    `\\b(${words.map(str => `(${escapeStringRegexp(str)})`).join('|')})\\b`,
    'gui',
  );
  const whiteSpaceRegex = /\s/i;
  let chunks = [];
  let chunkLenSum = 0;
  let match;
  const textWoAccents = removeAccents(text);
  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(textWoAccents))) {
    if (!match) break;
    let start = match.index;
    let end = regex.lastIndex;

    if (end > start) {
      // do not return zero-length matches
      // make sure we start/end on word boundaries
      start = Math.max(0, start - (options.padding + 1));
      end = Math.min(text.length, end + (options.padding + 1));

      if (start !== 0) {
        while (!whiteSpaceRegex.test(text[start])) start++;
        start++;
      }
      if (end !== text.length) {
        while (!whiteSpaceRegex.test(text[end])) end--;
        end--;
      }

      chunkLenSum += end - start + 1;
      chunks.push({ start, end });

      // stop when we have enough text
      if (chunkLenSum >= options.maxLength) {
        break;
      }
    }

    // Prevent browsers like Firefox from getting stuck in an infinite loop
    // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
    if (match.index === regex.lastIndex) {
      regex.lastIndex++;
    }
  }

  if (!chunks.length) {
    return text.substring(0, options.maxLength);
  }

  // combine overlapping chunks
  chunks = chunks
    .sort((first, second) => first.start - second.start)
    .reduce((processedChunks, nextChunk) => {
      // First chunk just goes straight in the array...
      if (processedChunks.length === 0) {
        return [nextChunk];
      }
      // ... subsequent chunks get checked to see if they overlap...
      const prevChunk = processedChunks.pop();
      if (nextChunk.start <= prevChunk.end) {
        // It may be the case that prevChunk completely surrounds nextChunk, so take the
        // largest of the end indeces.
        const endIndex = Math.max(prevChunk.end, nextChunk.end);
        processedChunks.push({ highlight: false, start: prevChunk.start, end: endIndex });
      } else {
        processedChunks.push(prevChunk, nextChunk);
      }
      return processedChunks;
    }, []);

  // reduce chunks to get the excerpt
  let excerpt = chunks
    .map(chunk =>
      text
        .substring(chunk.start, chunk.end + 1)
        .replace(regex, s => `<mark class='${options.highlightClass}'>${s}</mark>`))
    .join('... ');
  if (chunks[chunks.length - 1].end !== text.length) {
    excerpt += '...';
  }
  return excerpt;
}

function HighlightWords({ text, words, highlightClass, padding, maxLength, ...rest }) {
  const html = excerptSearch(text, words, { maxLength, highlightClass, padding });
  // eslint-disable-next-line react/no-danger
  return <span {...rest} dangerouslySetInnerHTML={{ __html: html }} />;
}

export default HighlightWords;
