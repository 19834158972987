/* eslint-disable react/jsx-no-target-blank */
import { useContext } from 'react';
import { useTranslation } from '../i18n';
import { getLinkRel } from '../utils';
import GlobalContext from './GlobalContext';
import Link from './link';

const getSocialIcon = platform => {
  if (platform === 'facebook') return 'fa-facebook-f';
  if (platform === 'twitter') return 'fa-twitter';
  if (platform === 'linkedin') return 'fa-linkedin-in';
  if (platform === 'instagram') return 'fa-instagram';
  if (platform === 'whatsapp') return 'fa-whatsapp-square';
  if (platform === 'telegram') return 'fa-telegram';
  return `fa-${platform}`;
};
const getSocialFullUrl = (platform, handle) => {
  if (platform === 'facebook') return `https://www.facebook.com/${handle}`;
  if (platform === 'twitter') return `https://twitter.com/${handle}`;
  if (platform === 'linkedin') return `https://www.linkedin.com/${handle}`;
  if (platform === 'instagram') return `https://www.instagram.com/${handle}`;
  if (platform === 'whatsapp') return `https://wa.me/${handle}`;
  if (platform === 'telegram') return `https://t.me/${handle}`;
  return handle; // unknown
};

const landingLanguages = ['en', 'tr', 'fr', 'it'];

function Footer() {
  const { desk, query } = useContext(GlobalContext);
  const { t } = useTranslation(['common']);
  const socialLinks = desk.socialLinks.filter(link => !!link?.url);
  const footerLinks = desk.footerLinks.filter(link => !!link?.url);
  return (
    <footer className="footer px">
      <div className="content">
        <Link route="home">
          {desk.logoUrl ? (
            <a style={{ fontSize: 0 }}>
              <img src={desk.logoUrl} className="logo" alt="" />
            </a>
          ) : (
            <a className="title">{desk.languageFields.title}</a>
          )}
        </Link>
        {footerLinks.length > 0 && (
          <ul className="links lightText">
            {footerLinks.map(link => (
              <li className="link hoverable" key={`${link.url}-${link.title}`}>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href={link.url} target="_blank" rel={getLinkRel(link.url)}>
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
        )}
        {socialLinks.length > 0 && (
          <ul className="links">
            {socialLinks.map(link => (
              <li className="link hoverable" key={`${link.platform}-${link.url}`}>
                <a
                  className="lightText"
                  href={getSocialFullUrl(link.platform, link.url)}
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                  rel={getLinkRel(getSocialFullUrl(link.platform, link.url))}
                >
                  <i className={`fab ${getSocialIcon(link.platform)}`} />
                </a>
              </li>
            ))}
          </ul>
        )}
        {desk.showInfosetLink !== false && (
          <div className="advert lightText hoverable">
            <img src="https://cdn.infoset.app/logo.svg" alt={t('infosetLogoAlt')} />
            <a
              href={`https://infoset.app/${
                query.language !== 'en' && landingLanguages.includes(query.language)
                  ? `${query.language}/`
                  : ''
              }?utm_source=${desk.slug}&utm_medium=hc`}
              target="_blank"
            >
              {t('poweredByInfoset')}
            </a>
          </div>
        )}
      </div>

      <style jsx>
        {`
          .footer {
            background: #fff;
            padding-top: 35px;
            padding-bottom: 35px;
            text-align: center;
            font-size: 14px;
          }
          .logo {
            max-height: 32px;
            filter: grayscale(100%) contrast(80%);
          }
          .title {
            font-weight: 500;
            font-size: 17px;
            margin: 0;
            opacity: 0.7;
            line-height: 1.2;
          }
          .links {
            padding: 0;
            margin: 1.1em 0;
          }
          .link {
            list-style-type: none;
            display: inline-block;
            margin: 0 10px;
          }
          .link .fab {
            font-size: 16px;
          }
          .advert {
            margin-top: 20px;
          }
          .advert img {
            height: 16px;
            vertical-align: middle;
            opacity: 0.5;
            filter: grayscale(100%);
            border-radius: 2px;
          }
          .advert a {
            padding-left: 5px;
            line-height: 20px;
            vertical-align: middle;
          }
        `}
      </style>
    </footer>
  );
}

export default Footer;
