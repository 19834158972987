import React, { useState } from 'react';

function Tooltip({ message, children }) {
  const [visible, setVisible] = useState(false);

  const handleMouseEnter = () => {
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <div className="tooltipRoot">
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      {visible && <div className="tooltip">{message}</div>}
      <style jsx>
        {`
          .tooltipRoot {
            position: relative;
            display: inline-flex;
          }
          .tooltipRoot > div:first-child {
            display: inline-flex;
          }
          .tooltip {
            position: absolute;
            background: rgba(0, 0, 0, 0.8);
            color: #fff;
            padding: 4px 8px;
            border-radius: 4px;
            font-size: 12px;
            white-space: nowrap;
            z-index: 999,
            top: 100%;
            left: 50%;
            transform: translateX(-50%) translateY(-100%);
            top: -4px;
            opacity: 0.9,
            transition: opacity 0.2s ease;
          }
        `}
      </style>
    </div>
  );
}

export default Tooltip;
