const routes = require('next-routes');

const langRegex = '([a-zA-Z]{2})';
const baseRoute = process.env.BASE_ROUTE_REGEX;

module.exports = routes()
  .add('redirectDefaultLanguage', `${baseRoute || '/'}`)
  .add('home', `${baseRoute || ''}/:language${langRegex}`)
  .add('search', `${baseRoute || ''}/:language${langRegex}/search`)
  .add('collection', `${baseRoute || ''}/:language${langRegex}/collections/:_id([0-9]+)-:slug?`)
  .add('article', `${baseRoute || ''}/:language${langRegex}/articles/:_id([0-9]+)-:slug?`)
  .add('notFoundPage', `${baseRoute || ''}/:language${langRegex}/:rest`); // used to catch the language and base route
