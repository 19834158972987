import cx from 'classnames';
import { useContext } from 'react';
import { useTranslation } from '../i18n';
import { encodeHtml, lightenColor } from '../utils';
import GlobalContext from './GlobalContext';
import Avatar from './avatar';
import HighlightWords from './highlight';
import Link from './link';
import PinToFav from './pinToFav';

function ArticleCard({
  _id,
  title,
  spoiler,
  user,
  slug,
  updatedAt,
  isPinned = false,
  forQuickSearch = false,
  highlightWords = [],
}) {
  const { desk, query } = useContext(GlobalContext);
  const { t } = useTranslation(['common']);
  const lightPrimaryColor = lightenColor(desk.color, 10);
  const hasUser = !!user?.name;
  const writtenByText = hasUser
    ? t('writtenByLabel1', { user1: encodeHtml(user.name), count: 1 })
    : '';

  return (
    <Link route="article" params={{ _id, slug }}>
      <a className={cx('articleCard', { qsResult: forQuickSearch })}>
        <article>
          <div className="title">
            <h2>
              {highlightWords.length ? (
                <HighlightWords
                  highlightClass="mark"
                  text={title || ''}
                  words={highlightWords}
                  maxLength={200}
                />
              ) : (
                title
              )}
            </h2>
            {isPinned && (
              <div className="pinButton">
                <PinToFav articleId={_id} />
              </div>
            )}
          </div>
          <p>
            {highlightWords.length ? (
              <HighlightWords
                highlightClass="mark"
                text={spoiler || ''}
                words={highlightWords}
                maxLength={200}
              />
            ) : (
              spoiler
            )}
          </p>
          {!forQuickSearch && (
            <div className="bottom">
              {hasUser && (
                <div className="bottomLeft">
                  <Avatar user={user} />
                </div>
              )}
              <div className="bottomRight lightText">
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: writtenByText }} />
                <div>
                  {t('lastUpdate')}: {new Date(updatedAt).toLocaleDateString(query.language)}
                </div>
              </div>
            </div>
          )}
        </article>
        <style jsx>
          {`
            .articleCard {
              background: white;
              box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
              width: 100%;
              display: block;
              border: 1px solid #e2e6e9;
              transition: box-shadow 0.2s;
              margin-top: 5px;
              border-radius: 4px;
            }
            .articleCard:hover,
            .articleCard:focus {
              box-shadow: 0 5px 12px rgba(0, 0, 0, 0.14);
              background: #fcfcfc;
            }
            article {
              position: relative;
              color: #666;
              padding: 30px;
            }
            .title {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

            .title h2 {
              color: ${lightPrimaryColor};
              margin: 0 0 3px;
              font-size: 17px;
              transition: color 0.2s;
              font-weight: 500;
            }

            .title .pinButton {
              display: flex;
              align-items: center;
              height: 0;
              font-size: 20px;
            }

            .articleCard:hover h2,
            .articleCard:focus h2 {
              color: ${desk.color};
            }
            .articleCard :global(.mark) {
              color: inherit;
              background-color: inherit;
              font-weight: 600;
            }
            p {
              margin: 0 0 10px;
            }
            .bottom {
              display: flex;
              flex-flow: row nowrap;
            }
            .bottomLeft {
              margin-right: 10px;
            }
            .bottomRight {
              font-size: 13px;
              line-height: 1.3;
            }

            .qsResult:first-child {
              margin-top: 0;
            }
            .qsResult p {
              margin-bottom: 0;
              // max 2 lines
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            .qsResult article {
              padding: 15px 30px;
            }
            @media screen and (max-width: 480px) {
              .qsResult article {
                padding: 12px 20px;
              }
            }
          `}
        </style>
      </a>
    </Link>
  );
}

export default ArticleCard;
